import React, { useEffect, useState } from "react";
import {
  netAnnualIncome,
  investmentExperience,
  investmentKnowledge,
  investmentStrategy,
  currency,
  liquidNetWorth,
  totalNetWorth,
  riskProfile,
  relevantInvestment,
} from "../../../config/data";
import { getUserKyc, updateUserKyc } from "../../../config/user";
import { customModal } from "../../../utils/modalUtils";
import { CheckIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useModal } from "../../../context/ModalContext";
import { useDispatch, useSelector } from "react-redux";
import FirstSection from "./FirstSection";
import DotLoader from "../../../components/DotLoader";
import { useNavigate } from "react-router-dom";
import { setKycDetails } from "../../../store/actions/userActions";

export default function KycForm() {
  const [formData, setFormData] = useState({
    investExperience: "",
    investKnowledge: "",
    investStrategy: "",
    riskProfile: "",
    relevantInvestment: "",
    selectCurrency: "",
    liquidNetWorth: "",
    totalNet: "",
    netIncome: "",
  });
  const { showModal, hideModal } = useModal();
  const userId = useSelector((state) => state.user.userId);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchKycDetails = async () => {
      try {
        const details = await getUserKyc(userId);
        if (details) {
          setFormData((prevDetails) => ({
            ...prevDetails,
            netIncome: details.netIncome || "",
            investExperience: details.investExperience || "",
            investKnowledge: details.investKnowledge || "",
            investStrategy: details.investStrategy || "",
            riskProfile: details.riskProfile || "",
            relevantInvestment: details.relevantInvestment || "",
            selectCurrency: details.selectCurrency || "",
            liquidNetWorth: details.liquidNetWorth || "",
            totalNet: details.totalNet || "",
          }));
        } else {
          setFormData({
            netIncome: "",
            investExperience: "",
            investKnowledge: "",
            investStrategy: "",
            riskProfile: "",
            relevantInvestment: "",
            selectCurrency: "",
            liquidNetWorth: "",
            totalNet: "",
          });
        }
      } catch (err) {
        console.error("Error fetching KYC details:", err);
      }
    };

    if (userId) {
      fetchKycDetails();
    }
  }, [userId]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked
          ? [...prevData[name], value]
          : prevData[name].filter((item) => item !== value),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const response = await updateUserKyc(userId, formData);

    if (response.success) {
      customModal({
        showModal,
        title: "Success!",
        text: "KYC details updated successfully.",
        showConfirmButton: false,
        icon: CheckIcon,
        iconBgColor: "bg-green-100",
        iconTextColor: "text-green-600",
        buttonBgColor: "bg-green-600",
        timer: 2000,
        onClose: hideModal,
      });

      // dispatch(setKycDetails(completion));
      navigate("/dashboard");
    } else {
      console.error("Error updating KYC details:", response.error);
      customModal({
        showModal,
        title: "Error!",
        text: `There was a problem updating KYC details: ${response.error}`,
        showConfirmButton: false,
        icon: ExclamationTriangleIcon,
        iconBgColor: "bg-red-100",
        iconTextColor: "text-red-600",
        buttonBgColor: "bg-red-600",
        onClose: hideModal,
        timer: 3000,
      });
    }

    setIsSubmitting(false);
  };

  return (
    <div className="px-2 pb-10 lg:px-24 sm:px-8 bg-gray-50 min-h-[calc(100vh_-_64px)]">
      <div className="py-8 text-left">
        <h3 className="text-lg font-semibold leading-6 text-gray-900">
          Know Your Customer Form
        </h3>
        <p className="mt-1 text-sm text-gray-500">
        As part of our legal obligation to comply with Anti-Money Laundering (AML) regulations, we implement Know Your Client (KYC) procedures to assess client risk and prevent illicit financial activity. These procedures require us to verify your identity, understand your financial activities, and evaluate any potential money laundering risks. For further details on our KYC policy, please contact your account broker.
        </p>

        {/* Removing steps and showing only the first section */}
        <div className="mt-6 mb-6" aria-hidden="true">
          <div className="overflow-hidden rounded-full bg-gray-200">
            <div className="h-2 bg-secondary rounded-full" style={{ width: "100%" }} />
          </div>
        </div>

        {/* Render only the first section */}
        <FirstSection
          formData={formData}
          handleChange={handleInputChange}
          investStrategy={investmentStrategy}
          riskProfile={riskProfile}
          investExperience={investmentExperience}
          investKnowledge={investmentKnowledge}
          relevantInvestment={relevantInvestment}
          netAnnualIncome={netAnnualIncome}
          liquidNetWorth={liquidNetWorth}
          currencies={currency}
          totalNetWorth={totalNetWorth}
        />
      </div>

      <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8 mt-4">
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => navigate("/dashboard")}
        >
          Skip for later
        </button>
        <button
          type="submit"
          className="rounded-md bg-secondary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <div className="flex w-full justify-center align-middle gap-2">
              <span>Submitting</span>
              <DotLoader />
            </div>
          ) : (
            "Submit KYC Form"
          )}
        </button>
      </div>
    </div>
  );
}
