// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAjrbv8LT7AJQ__dbrkPlcs-XMK_rkDebM",
  authDomain: "holtway-global.firebaseapp.com",
  databaseURL: "https://holtway-global-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "holtway-global",
  storageBucket: "holtway-global.appspot.com",
  messagingSenderId: "268753311066",
  appId: "1:268753311066:web:708ff2ac4a1b1d53f14f63",
  measurementId: "G-R4ZLFYDV06"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app); 
export const storage = getStorage(app);