import React from "react";

export default function FirstSection({
  formData,
  handleChange,
  investExperience,
  investKnowledge,
  investStrategy,
  riskProfile,
  relevantInvestment,
  netAnnualIncome,
  currencies,
  liquidNetWorth,
  totalNetWorth,
}) {
  return (
    <div className="space-y-10 divide-y divide-gray-900/10 mt-12">
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Your Trading Knowldge and Experience
          </h2>
        </div>

        <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="investment-experience"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Are you accustomed to making your own financial decisions?
                </label>
                <div className="mt-2">
                  <select
                    name="investExperience"
                    value={formData.investExperience}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-accent focus:ring-accent sm:text-sm"
                  >
                    <option value="">Select your investment experience</option>
                    {investExperience.map((investment, index) => (
                      <option value={investment} key={index}>
                        {investment}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="investment-knowledge"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Please pick any of the following that matches your knowledge.
                </label>
                <div className="mt-2">
                  <select
                    name="investKnowledge"
                    value={formData.investKnowledge}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-accent focus:ring-accent sm:text-sm"
                  >
                    <option value="">Select your investment knowledge</option>
                    {investKnowledge.map((investment, index) => (
                      <option value={investment} key={index}>
                        {investment}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="investment-strategy"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Please pick any of the following that matches your main
                  objective.
                </label>
                <div className="mt-2">
                  <select
                    name="investStrategy"
                    value={formData.investStrategy}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-accent focus:ring-accent sm:text-sm"
                  >
                    <option value="">Select your investment strategy</option>
                    {investStrategy.map((investment, index) => (
                      <option value={investment} key={index}>
                        {investment}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="riskProfile"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Please select one of the following which matches your
                  circumstances.
                </label>
                <div className="mt-2">
                  <select
                    name="riskProfile"
                    value={formData.riskProfile}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-accent focus:ring-accent sm:text-sm"
                  >
                    <option value="">Select your risk profile</option>
                    {riskProfile.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="relevantInvestment"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Please tick any of the following investments you consider
                  yourself to be familiar with from your previous investment
                  experience.
                </label>
                <div className="mt-2">
                  {relevantInvestment.map((item, index) => (
                    <div key={index}>
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          name="relevantInvestment"
                          value={item}
                          checked={formData.relevantInvestment.includes(item)}
                          onChange={handleChange}
                          className="form-checkbox h-4 w-4 text-secondary border-gray-300 rounded focus:ring-accent"
                        />
                        <span className="ml-2 text-sm text-gray-700">
                          {item}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>


              <div className="col-span-full">
                <label
                  htmlFor="select-currency"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Please select one of the following currencies for the below
                  options.
                </label>
                <div className="mt-2">
                  <select
                    name="selectCurrency"
                    value={formData.selectCurrency}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-accent focus:ring-accent sm:text-sm"
                  >
                    <option value="">Select your currency</option>
                    {currencies.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              
              <div className="col-span-full">
                <label
                  htmlFor="netIncome"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Your net annual income.
                </label>
                <div className="mt-2">
                  <select
                    name="netIncome"
                    value={formData.netIncome}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-accent focus:ring-accent sm:text-sm"
                  >
                    <option value="">Select your net annual income</option>
                    {netAnnualIncome.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="liquidNetWorth"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Your liquid net worth.
                </label>
                <div className="mt-2">
                  <select
                    name="liquidNetWorth"
                    value={formData.liquidNetWorth}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-accent focus:ring-accent sm:text-sm"
                  >
                    <option value="">Select your liquid net worth</option>
                    {liquidNetWorth.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="totalNet"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Your total net worth.
                </label>
                <div className="mt-2">
                  <select
                    name="totalNet"
                    value={formData.totalNetWorth}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-accent focus:ring-accent sm:text-sm"
                  >
                    <option value="">
                      Select your total net worth
                    </option>
                    {totalNetWorth.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
